import React from "react";
import './Topbar.scss'

const Topbar = () => {
  return (
    <div className="topbar">
      <div className="title">TSX PIZZERIAS</div>
      <div className="buttons">
        <button id="delivery">DELIVERY</button>
        <button id="pickup">PICK UP</button>
      </div>
      <div className="mid">
        <span>25mins</span>
        <span>$20</span>
        <span>Discounts</span>
      </div>
      <div className="bottom">
        <span>Menu Hours: 10:00 AM to 11:00 PM</span>
      </div>
    </div>
  );
};

export default Topbar;
