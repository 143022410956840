import { BrowserRouter, Route, Routes } from "react-router-dom";
import Summary from "./pages/summary/Summary";
import Topbar from "./components/topbar/Topbar";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Topbar />

        <Routes>
          <Route path="/" element={<Summary />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
