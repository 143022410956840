import React from "react";
import "./Summary.scss";
import axios from "axios";

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const __DEV__ = document.domain === "localhost";

const Summary = () => {
  const displayRazorPay = async () => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const data = await axios
      .post("/rpay")
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    const options = {
      key: __DEV__ ? "rzp_test_AWx3ICqrH8WP99" : "PRODUCTION_KEY",
      amount: "49900",
      currency: "INR",
      name: "TSX PIZZERIAS",
      description: "Payment for your order",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/Service_mark.svg/1280px-Service_mark.svg.png",
      order_id: "order_IIlKn4hJgduhFd",
      handler: function (response) {
        alert(response.razorpay_payment_id);
        alert(response.razorpay_order_id);
        alert(response.razorpay_signature);
      },
      prefill: {
        name: "Gaurav Kumar",
      },
    };

    var paymentObj = new window.Razorpay(options);

    paymentObj.open();
  };

  return (
    <div className="summary">
      <div className="order">
        <div className="title">
          <span id="main">Your Order</span>
          <span id="add">Add items +</span>
        </div>
        <div className="dishes">
          <div className="item">
            <div className="left">
              <span className="index">1</span>
              <div className="name">
                <span className="one">Margarita</span>
                <span className="two">crab & cucumber</span>
              </div>
            </div>
            <div className="right">
              <span>$412.00</span>
            </div>
          </div>

          <div className="item">
            <div className="left">
              <span className="index">2</span>
              <div className="name">
                <span className="one">Margarita B</span>
                <span className="two">tuna & cucumber</span>
              </div>
            </div>
            <div className="right">
              <span>$112.00</span>
            </div>
          </div>

          <div className="item">
            <div className="left">
              <span className="index">3</span>
              <div className="name">
                <span className="one">Margarita C</span>
                <span className="two">
                  smoked salmon over rice with extra sauce to check if this line
                  works
                </span>
              </div>
            </div>
            <div className="right">
              <span>$1236.00</span>
            </div>
          </div>
        </div>
      </div>

      <div className="sum">
        <div className="title">
          <span>Summary</span>
        </div>
        <div className="item">
          <span>Subtotal</span>
          <span>$1760.00</span>
        </div>
        <div className="item" id="discount">
          <span>Discount</span>
          <span>-$759.50</span>
        </div>
        <div className="item">
          <span>Delivery Fee</span>
          <span>$12.00</span>
        </div>
        <div className="item">
          <span>Taxes</span>
          <span>$46.15</span>
        </div>
        <div className="item" id="total">
          <span>Total</span>
          <span>$1058.65</span>
        </div>
      </div>
      <button onClick={displayRazorPay} className="order-btn">
        PLACE ORDER
      </button>
    </div>
  );
};

export default Summary;
